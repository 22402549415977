import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { Container, Row, Column } from 'styled-bootstrap-components';
import Layout from '../components/Layout';

const NotFoundPage = props => (
  <Layout location={props.location}>
    <Helmet title="404 - Eddy Herrera" />
    <Container>
      <Row mt="5rem">
        <Column sm={12} bg="white" p="2rem">
          <h1>¡Página no encontrada!</h1>
          <p>
            Lo sentimos, no pudimos encontrar la página que estás buscando. <Link to="/">Haz click aquí</Link> para
            volver a la página de inicio.
          </p>
        </Column>
      </Row>
    </Container>
  </Layout>
);

export default NotFoundPage;
